@import 'utils/style/mixins.scss';

.PolicyDetail .DetailInfo {
  strong {
    display: block;
    margin-bottom: var(--spacing-base);
  }
  .CarrierLogo {
    position: absolute;
    right: var(--spacing-base);
    top: 50%;
    transform: translateY(-50%);
    padding-left: var(--spacing-m2);
    border-left: 1px solid var(--grey-l3);
    img {
      width: 90px;
    }
  }
  .Date {
    float: left;
    margin-right: var(--spacing-p1);
    small {
      display: block;
    }
  }
}

@media (max-width: 400px){ 
  .PolicyDetailDates {
    padding: 0;
    display: grid;
  }
}

.CoverageLists {
  .CoveragesTitle {
    display: block;
    margin-bottom: var(--spacing-m2);
  }
}

.zebra-list {
  .CoverageItem {
    margin-left: calc(16px * -1);
    margin-right: calc(16px * -1);
    padding-left: var(--spacing-base);
    padding-right: var(--spacing-base);
    .Btn {
      float: right;
      margin-left: var(--spacing-m2);
      svg {
        width: 15px;
        fill: var(--accent);
      }
    }
    .ItemTitle {

    }
    .CovHelp {
      max-height: 0;
      overflow: hidden;
      transition: max-height 321ms ease-out;
      small {
        display: block;
        padding-top: var(--spacing-m1);
        color: var(--grey-d1);
      }
      &.visible {
        max-height: 400px;
        transition: max-height 281ms ease-out;
      }
    }
  }
  &:nth-child(even) {
    .CoverageItem {
      .CovHelp {
        small {
          color: var(--grey-d2);
        }
      }
    }
  }
}