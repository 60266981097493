.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 4px;
  max-width: 500px;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.modal-body {
  margin-bottom: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-accept:hover {
  background-color: #0056b3;
}

.button-group {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.modal-contact {
  margin-right: auto;
}