@import 'utils/style/mixins.scss';

.FormWrapper {
  max-width: 650px;
  margin: 0 auto 100px auto;
}

.BasePage.requests {
  margin-bottom: 100px;
}

.SubForm {
  position: absolute;
  padding-bottom: 100px;
}

.RequirementList {
  ul {
    @include bullet-list;
  }
  .Header {
    text-transform: uppercase;
  }
}

.reason-to-stay {
  ul {
    span {
      margin-left: -30px;
    }
  }
}

.driverLicenceHeader {
  display: flex;
  justify-content: space-between;
}

.help-circle-icon {
  margin-left: 0.5rem;
  width: 1rem;
  height: 1rem;
  fill: var(--primary-d1);
}

.finance-company-search {
  margin-bottom: 0.5rem !important;
}
