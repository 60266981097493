.login-form-wrapper {
  position: relative;
  
  &.is-loading form {
    pointer-events: none;
    opacity: 0.7;
  }
}

.login-overlay {
  position: absolute;
  top: 6rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}